import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
  isAuthenticated: boolean
  checkingIfAuthenticated: boolean
}

const initialState: AuthState = {
  isAuthenticated: false,
  checkingIfAuthenticated: true,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCheckingIfAuthenticated: (state, action) => ({
      ...state,
      checkingIfAuthenticated: action.payload,
    }),
    afterLogin: () => ({
      isAuthenticated: true,
      checkingIfAuthenticated: false,
    }),
    afterLogout: () => ({
      isAuthenticated: false,
      checkingIfAuthenticated: false,
    }),
  },
})

export const { afterLogin, afterLogout, setCheckingIfAuthenticated } = authSlice.actions
