import { css } from '@emotion/react'
import { criticalErrorOccurred } from '../../state/reducers/root-reducer'
import { useAppDispatch, useAppSelector } from '../../state/store'

// async function cleanGoogleDrive(accessToken: string) {
//   const drive = driveService({ accessToken })
//   const response = await drive.listFiles()

//   await Promise.all(response.files.map(file => drive.deleteFile(file.id)))
//   window.location.href = window.location.origin
// }

// async function deleteEverything(accessToken: string) {
//   await cleanGoogleDrive(accessToken)
//   localStorage.clear()
//   window.location.href = window.location.origin
// }

// const useStyles = makeStyles({
//   suggestion: {
//     margin: '2rem 0',
//   },
//   button: {
//     textAlign: 'center',
//   },
// })

export const ErrorResolver = () => {
  // const { loginWithRedirect } = useAuth0()
  const dispatch = useAppDispatch()
  const { checkingIfAuthenticated } = useAppSelector((state) => state.auth)
  dispatch(criticalErrorOccurred)

  if (checkingIfAuthenticated) {
    return <div>Something bad happened. Please wait..</div>
  }

  return (
    <div
      css={css`
        margin: 1rem;
      `}
    >
      <h1>Error</h1>
      <p>Oh no! We probably did something bad and screwed up :(</p>
      <p>We will try to help you fix it:</p>
      {/* 
      {
        !isAuthenticated
          ? (
            <div>
              <h3>First, you will need to login</h3>
              <div className={classes.button}><Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>Login</Button></div>
            </div>
          )
          : (
            <div>
              <div>
                <hr className={classes.suggestion} />
                <h3>You can clean the storage in google drive</h3>
                <p> We will re-save all your bookmarks to google drive. You may lose some changes if you did changed some bookmarks from another computer or browser.</p>
                <div className={classes.button}><Button variant="contained" color="primary" onClick={() => cleanGoogleDrive(accessToken)}>Clean storage</Button></div>
              </div>

              <div className={classes.suggestion}>
                <hr className={classes.suggestion} />
                <h3>Last resort, delete all my bookmarks and try again</h3>
                <p>We will delete EVERYTHING. You will have to add all bookmarks all over again.</p>

                <div className={classes.button}><Button variant="contained" color="secondary" onClick={() => deleteEverything(accessToken)}>DELETE EVERYTHING</Button></div>
              </div>
            </div>
          )
      } */}
    </div>
  )
}
