import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './state/store'
import packageJson from '../package.json'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './globals.css'

console.log('Welcome to bookmark.no <3')
console.log(`Version: ${packageJson.version}`)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
