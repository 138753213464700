import { Component } from 'react'
import { ErrorResolver } from './ErrorResolver'

export interface ErrorBoundaryProps {
  children: JSX.Element
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {

  state = {
    hasError: false,
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    })
  }

  render() {
    return (
      <div>
        {
          this.state.hasError ? <ErrorResolver /> : this.props.children
        }
      </div>
    )
  }
}

export default ErrorBoundary