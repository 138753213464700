import { css } from '@emotion/react'
import { CardContent } from '@mui/material'
import Card from '@mui/material/Card'

import { Draggable, Droppable } from 'react-beautiful-dnd'
import { GroupItem, GroupState } from '../../state/reducers/groups-reducer'
import { AppGroupItem } from './AppGroupItem'
import GroupHeader from './GroupHeader'

export interface GroupProps {
  group: GroupState
  index: number
}

export default ({ group, index }: GroupProps) => {
  return (
    <Card
      css={css`
        min-width: 300px;
        height: 100%;
      `}
    >
      <GroupHeader group={group} />

      <CardContent
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 0;
          height: 100%;
          &:last-child: {
            padding-bottom: 0;
          }
        `}
      >
        <Droppable droppableId={`group-${index}`}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              css={css`
                position: relative;
                height: 100%;
              `}
            >
              {group.items.map((item: GroupItem, index: number) => {
                const { id } = item

                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <div
                        style={provided.draggableProps.style}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <AppGroupItem group={group} item={item} />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </CardContent>
    </Card>
  )
}
