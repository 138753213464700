import { Field, Form } from 'react-final-form'
import { nameValidator } from '../../validators/name-validator'
import { requiredValidator } from '../../validators/required-validator'
import { urlValidator } from '../../validators/url-validator'
import { combineValidators } from '../../validators/validator-tools'
import { ApproveButton, CancelButton } from '../buttons/buttons'
import FormTextField from '../form/FormTextField'
import SpacedActions from '../spaced-actions/SpacedActions'
import { css } from '@emotion/react'
import ArrowDownward from '@mui/icons-material/ArrowDownward.js'

export interface EditBookmarkProps {
  onSubmit: (form: Record<string, any>) => void
  onCancel: () => void
  initialValues: Record<string, any>
}

const EditBookmark = ({ onSubmit, onCancel, initialValues }: EditBookmarkProps) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      render={({ handleSubmit, form, valid }) => (
        <form
          css={css`
            display: flex;
            flex-direction: column;
            padding: 1.3rem 0 0.6rem 0;
          `}
          onSubmit={handleSubmit}
        >
          <Field
            name="name"
            component={FormTextField}
            validate={combineValidators(requiredValidator, nameValidator)}
            componentProps={{
              onKeyDown: (e: any) => e.stopPropagation(),
              classes: { root: '' },
              label: 'Bookmark name',
              variant: 'outlined',
            }}
          />

          <div
            css={css`
              text-align: center;
              margin: 0.5rem 0;
            `}
          >
            <ArrowDownward></ArrowDownward>
          </div>

          <Field
            name="uri"
            component={FormTextField}
            validate={combineValidators(requiredValidator, urlValidator)}
            componentProps={{
              classes: { root: '' },
              label: 'URL',
              variant: 'outlined',
            }}
          />

          <SpacedActions>
            <CancelButton
              buttonProps={{
                title: 'Cancel',
                color: 'secondary',
                onClick: () => {
                  form.reset()
                  onCancel()
                },
              }}
            ></CancelButton>

            <ApproveButton
              buttonProps={{
                type: 'submit',
                disabled: !valid,
                color: 'primary',
                title: 'Save changes',
              }}
            ></ApproveButton>
          </SpacedActions>
        </form>
      )}
    ></Form>
  )
}

export default EditBookmark
