import { createSelector } from 'reselect'
import { RootState } from '../store'
import { BookmarkState } from './bookmarks-reducer'
import { groupsAdapter, GroupState } from './groups-reducer'
import { selectActiveWorkspace } from './root-selectors'

export const {
  selectAll: selectAllGroups,
  selectById: selectGroupById,
  selectIds: selectGroupIds,
} = groupsAdapter.getSelectors<RootState>(state => state.groups)

export const selectGroupByIndex = createSelector(
  [selectAllGroups, (_: RootState, index: number) => index],
  (groups, index) => groups[index],
)

export const selectGroupIdsByActiveWorkspace = createSelector(
  [selectActiveWorkspace],
  (activeWorkspace) => activeWorkspace.items.map(item => item.id),
)

export const selectGroupsByActiveWorkspace = createSelector(
  [selectActiveWorkspace, (state: RootState) => state.groups],
  (activeWorkspace, groups) => activeWorkspace.items.map(({ id }) => groups.entities[id] as GroupState),
)

export const selectIsEditingAnyBookmark = createSelector(
  [(state: RootState, groupId: string) => state.groups.entities[groupId] as GroupState, (state: RootState) => state.bookmarks],
  (group, bookmarks) => group.items.map(({ id }) => bookmarks.entities[id] as BookmarkState).findIndex(bookmark => bookmark.isEditing) !== -1,
)