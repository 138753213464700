import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { CollectionDocumentV1 } from '../documents/AccountDocumentV1'
import { localstorageState } from '../localstorage'
import { mergeAll } from './custom-actions'
import {
  AddBookmarkToGroupAction,
  AddMenuToGroupAction,
  MoveGroupItemAction,
  MoveGroupItemToAnotherGroupAction,
  RemoveBookmarkFromGroupAction,
  RemoveMenuFromGroupAction,
  SetGroupNameAction,
  SetIsEditingGroupAction,
} from './groups-actions'
import { MergeAllAction } from './root-actions'
import { TUTORIAL_GROUPS } from './tutorial-state'
import { addGroupToWorkspace, removeGroupFromWorkspace } from './workspaces-reducer'
import { LocalCollectionDocumentV1 } from '../documents/LocalAccountDocumentV1'

export const GROUP_ITEM_MENU = 'menu'
export const GROUP_ITEM_BOOKMARK = 'bookmark'

export interface GroupItem {
  id: string
  type: string
}

export interface GroupState {
  id: string
  name: string
  items: GroupItem[]
  isDragging: boolean
  isEditing: boolean
}

export const groupsAdapter = createEntityAdapter<GroupState>({
  selectId: (group) => group.id,
})

const defaultGroup: GroupState = {
  id: nanoid(),
  name: '',
  isEditing: false,
  isDragging: false,
  items: [],
}
const initialEmptyState = groupsAdapter.getInitialState({})
const initialState = localstorageState
  ? localGroupDocumentsToState(localstorageState.groups)
  : groupDocumentsToState(TUTORIAL_GROUPS)

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: groupsAdapter.addMany(initialEmptyState, initialState),
  reducers: {
    setAllGroups: groupsAdapter.setAll,
    setGroupName(state, action: SetGroupNameAction) {
      const group = state.entities[action.payload.groupId]

      if (group) {
        group.name = action.payload.name
        return state
      } else {
        throw new Error(`Could not find group with id ${action.payload.groupId}`)
      }
    },

    setIsEditingGroup(state, action: SetIsEditingGroupAction) {
      const group = state.entities[action.payload.groupId]

      if (group) {
        group.isEditing = action.payload.isEditing
        return state
      } else {
        throw new Error(`Could not find group with id ${action.payload.groupId}`)
      }
    },

    moveGroupItem(state, action: MoveGroupItemAction) {
      const { groupId, fromItemIndex, toItemIndex } = action.payload
      const group = state.entities[groupId] as GroupState
      const removed = group.items.splice(fromItemIndex, 1)[0]
      group.items.splice(toItemIndex, 0, removed)

      return state
    },

    moveGroupItemToAnotherGroup(state, action: MoveGroupItemToAnotherGroupAction) {
      const { fromGroupId, toGroupId, fromItemIndex, toItemIndex } = action.payload
      const fromGroup = state.entities[fromGroupId] as GroupState
      const toGroup = state.entities[toGroupId] as GroupState

      const removed = fromGroup.items.splice(fromItemIndex, 1)[0]
      toGroup.items.splice(toItemIndex, 0, removed)

      return state
    },

    addBookmarkToGroup(state, action: AddBookmarkToGroupAction) {
      const group = state.entities[action.payload.groupId] as GroupState

      return groupsAdapter.updateOne(state, {
        id: action.payload.groupId,
        changes: {
          items: group.items.concat([
            {
              id: action.payload.entity.id,
              type: 'bookmark',
            },
          ]),
        },
      })
    },

    removeBookmarkFromGroup(state, action: RemoveBookmarkFromGroupAction) {
      const group = state.entities[action.payload.groupId] as GroupState

      return groupsAdapter.updateOne(state, {
        id: action.payload.groupId,
        changes: {
          items: group.items.filter((item) => item.id !== action.payload.bookmarkId),
        },
      })
    },

    addMenuToGroup(state, action: AddMenuToGroupAction) {
      const group = state.entities[action.payload.groupId] as GroupState

      return groupsAdapter.updateOne(state, {
        id: action.payload.groupId,
        changes: {
          items: group.items.concat([
            {
              id: action.payload.entity.id,
              type: GROUP_ITEM_MENU,
            },
          ]),
        },
      })
    },

    removeMenuFromGroup(state, action: RemoveMenuFromGroupAction) {
      const group = state.entities[action.payload.groupId] as GroupState

      return groupsAdapter.updateOne(state, {
        id: action.payload.groupId,
        changes: {
          items: group.items.filter((item) => item.id !== action.payload.menuId),
        },
      })
    },
  },

  extraReducers: (builder) => {
    builder.addCase(mergeAll, (state, action: MergeAllAction) => {
      return groupsAdapter.setAll(state, groupDocumentsToState(action.payload.groups))
    })
    builder.addCase(addGroupToWorkspace, (state, action) => groupsAdapter.addOne(state, action.payload.entity))
    builder.addCase(removeGroupFromWorkspace, (state, action) => groupsAdapter.removeOne(state, action.payload.groupId))
  },
})

export const {
  setGroupName,
  setIsEditingGroup,
  moveGroupItem,
  moveGroupItemToAnotherGroup,
  setAllGroups,
  addBookmarkToGroup,
  removeBookmarkFromGroup,
  addMenuToGroup,
  removeMenuFromGroup,
} = groupsSlice.actions

function groupDocumentsToState(groups: CollectionDocumentV1[]): GroupState[] {
  return groups.map((document) => {
    return {
      ...defaultGroup,
      id: document.id || defaultGroup.id,
      name: document.name || defaultGroup.name,
      items: document.items
        ? document.items.map((item) => ({
            id: item.id,
            type: item.type,
          }))
        : defaultGroup.items,
    }
  })
}

function localGroupDocumentsToState(groups: LocalCollectionDocumentV1[]): GroupState[] {
  return groupDocumentsToState(groups)
}
