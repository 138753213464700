import { LocalAccountDocumentV1 } from './documents/LocalAccountDocumentV1.js'

const LOCALSTORAGE_STATE = 'state'

const loadStateFromLocalstorage = (): LocalAccountDocumentV1 | undefined => {
  try {
    const serializedState = localStorage.getItem(LOCALSTORAGE_STATE)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const localstorageState = loadStateFromLocalstorage()

export const saveStateToLocalstorage = (state: LocalAccountDocumentV1) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(LOCALSTORAGE_STATE, serializedState)
  } catch {
    // ignore write errors
  }
}
