import { css } from '@emotion/react'
import { LinearProgress } from '@mui/material'

import { useSynchronizer } from '../../hooks/use-synchronizer'
import { useAppSelector } from '../../state/store'

export interface ProgressBarProps {}

const ProgressBar = ({}: ProgressBarProps) => {
  const { synchronizing } = useSynchronizer()
  const checkingIfAuthenticated = useAppSelector((state) => state.auth.checkingIfAuthenticated)
  const working = synchronizing || checkingIfAuthenticated

  return (
    <div
      css={css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        width: 100%;
      `}
    >
      {working ? <LinearProgress></LinearProgress> : null}
    </div>
  )
}

export default ProgressBar
