import { css } from '@emotion/react'

export interface InfoProps {
  header: string
  children: React.ReactNode
}

export default ({ header, children }: InfoProps) => {
  return (
    <div
      css={css`
        margin: 1rem;
        text-align: center;
      `}
    >
      <h1>{header}</h1>
      {children}
    </div>
  )
}
