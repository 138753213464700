import { createEntityAdapter, createSlice, Dictionary } from '@reduxjs/toolkit'
import { BookmarkDocumentV1 } from '../documents/AccountDocumentV1'
import { localstorageState } from '../localstorage'
import { SetFaviconErrorsAction, SetIsEditingBookmarkAction } from './bookmarks-actions'
import { mergeAll } from './custom-actions'
import { addBookmarkToGroup, removeBookmarkFromGroup } from './groups-reducer'
import { addBookmarkToMenu, removeBookmarkFromMenu } from './menus-reducer'
import { nanoid } from 'nanoid'
import { TUTORIAL_BOOKMARKS } from './tutorial-state'
import { LocalBookmarkDocumentV1 } from '../documents/LocalAccountDocumentV1.js'

export interface BookmarkState {
  id: string
  name: string
  uri: string
  isEditing: boolean
  faviconFailures: number
}

export const bookmarksAdapter = createEntityAdapter<BookmarkState>({
  selectId: (bookmark) => bookmark.id,
})

const defaultBookmark: BookmarkState = {
  id: nanoid(),
  name: '',
  uri: '',
  faviconFailures: 0,
  isEditing: false,
}
const initialEmptyState = bookmarksAdapter.getInitialState({})
const initialState = localstorageState
  ? localBookmarkDocumentsToState(localstorageState.bookmarks)
  : bookmarkDocumentsToState({}, TUTORIAL_BOOKMARKS)

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: bookmarksAdapter.addMany(initialEmptyState, initialState),
  reducers: {
    setFaviconErrors: (state, action: SetFaviconErrorsAction) => {
      return bookmarksAdapter.updateOne(state, {
        id: action.payload.bookmarkId,
        changes: {
          faviconFailures: action.payload.faviconFailures,
        },
      })
    },
    updateBookmark: bookmarksAdapter.updateOne,
    setAllBookmarks: bookmarksAdapter.setAll,
    setIsEditingBookmark(state, action: SetIsEditingBookmarkAction) {
      const bookmark = state.entities[action.payload.bookmarkId]

      if (bookmark) {
        bookmark.isEditing = action.payload.isEditing
        return state
      } else {
        throw new Error(`Could not find group with id ${action.payload.bookmarkId}`)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mergeAll, (state, action) => {
        return bookmarksAdapter.setAll(state, bookmarkDocumentsToState(state.entities, action.payload.bookmarks))
      })
      .addCase(addBookmarkToGroup, (state, action) => {
        return bookmarksAdapter.addOne(state, action.payload.entity)
      })
      .addCase(removeBookmarkFromGroup, (state, action) => {
        return bookmarksAdapter.removeOne(state, action.payload.bookmarkId)
      })
      .addCase(addBookmarkToMenu, (state, action) => {
        return bookmarksAdapter.addOne(state, action.payload.entity)
      })
      .addCase(removeBookmarkFromMenu, (state, action) => {
        return bookmarksAdapter.removeOne(state, action.payload.bookmarkId)
      })
  },
})

export const { updateBookmark, setIsEditingBookmark, setFaviconErrors, setAllBookmarks } = bookmarksSlice.actions

function bookmarkDocumentsToState(state: Dictionary<BookmarkState>, bookmarks: BookmarkDocumentV1[]): BookmarkState[] {
  return bookmarks.map((document) => {
    return {
      ...(state[document.id] || defaultBookmark),
      id: document.id || defaultBookmark.id,
      uri: document.uri || defaultBookmark.uri,
      name: document.name || defaultBookmark.name,
    }
  })
}

function localBookmarkDocumentsToState(bookmarks: LocalBookmarkDocumentV1[]): BookmarkState[] {
  return bookmarks.map((document) => {
    return {
      ...defaultBookmark,
      id: document.id || defaultBookmark.id,
      uri: document.uri || defaultBookmark.uri,
      name: document.name || defaultBookmark.name,
      faviconFailures: document.faviconFailures || defaultBookmark.faviconFailures,
    }
  })
}
