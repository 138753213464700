import { TextField, TextFieldProps } from '@mui/material'

import { FieldRenderProps } from 'react-final-form'

interface HelperProps {
  error: string[] | string
}

const Helper = ({ error }: HelperProps) => (
  <span>
    {Array.isArray(error) ? (
      error.map((error, i) => (
        <span key={i}>
          {error}
          <br />
        </span>
      ))
    ) : (
      <span>{error}</span>
    )}
  </span>
)

export interface FormTextField extends FieldRenderProps<string, any> {
  componentProps: TextFieldProps
}

const FormTextField = ({ input, componentProps, meta: { touched, error, invalid } }: FormTextField) => {
  return (
    <TextField
      error={touched && invalid}
      helperText={touched && invalid && <Helper error={error} />}
      {...input}
      {...componentProps}
    />
  )
}

export default FormTextField
