import { useCallback, useEffect } from 'react'
import { afterLogin, afterLogout, setCheckingIfAuthenticated } from '../state/reducers/auth-reducer'
import { useAppDispatch } from '../state/store'

export function useAuth() {
  const dispatch = useAppDispatch()

  const checkAuthCookie = useCallback(() => {
    const authCookie = document.cookie
      .split(/;\s?/)
      .map((cookie) => cookie.split('='))
      .find((cookie) => cookie[0] === 'appSessionExpires')

    if (authCookie) {
      dispatch(afterLogin())
    } else {
      dispatch(afterLogout())
    }
    dispatch(setCheckingIfAuthenticated(false))
  }, [])

  useEffect(() => {
    checkAuthCookie()
    setInterval(checkAuthCookie, 10000)
  }, [])

  return {
    login: () => {
      dispatch(setCheckingIfAuthenticated(true))
      window.location.href = '/api/login'
    },
    logout: () => {
      dispatch(setCheckingIfAuthenticated(true))
      window.location.href = '/api/logout'
    },
  }
}
