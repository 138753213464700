import validator from 'validator'

export const urlValidator = (value: any) => {

  if (value === undefined || value === null) {
    return undefined
  }

  if (validator.isURL(value)) {
    return undefined
  } else {
    return 'Must be an URL'
  }
}