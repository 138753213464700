import { createSlice } from '@reduxjs/toolkit'
import { localstorageState } from '../localstorage'
import { authSlice } from './auth-reducer'
import { bookmarksSlice } from './bookmarks-reducer'
import { changesSlice } from './change-reducer'
import { mergeAll } from './custom-actions'
import { dndSlice } from './dnd-reducer'
import { groupsSlice } from './groups-reducer'
import { menusSlice } from './menus-reducer'
import { MergeAllAction } from './root-actions'
import { TUTORIAL_WORKSPACE_ID } from './tutorial-state'
import { workspacesSlice } from './workspaces-reducer'

export const isSavingWorkspacesSlice = createSlice({
  name: 'isSavingWorkspaces',
  initialState: false,
  reducers: {
    setIsSavingWorkspaces: (_, action) => action.payload,
  },
})

export const isCriticalErrorSlice = createSlice({
  name: 'isCriticalError',
  initialState: false,
  reducers: {
    criticalErrorOccurred: () => true,
  },
})

export const { criticalErrorOccurred } = isCriticalErrorSlice.actions

export const activeWorkspaceSlice = createSlice({
  name: 'activeWorkspaceSlice',
  initialState: localstorageState ? localstorageState.activeWorkspace : TUTORIAL_WORKSPACE_ID,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mergeAll, (state, action: MergeAllAction) => {
      return action.payload.activeWorkspace
    })
  },
})

export const hasLoadedWorkspacesSlice = createSlice({
  name: 'hasLoadedWorkspaces',
  initialState: false,
  reducers: {
    setHasLoadedWorkspaces: (_, action) => {
      return action.payload
    },
  },
})

export default {
  changes: changesSlice.reducer,
  auth: authSlice.reducer,
  dnd: dndSlice.reducer,
  isCriticalError: isCriticalErrorSlice.reducer,
  isSavingWorkspaces: isSavingWorkspacesSlice.reducer,
  activeWorkspace: activeWorkspaceSlice.reducer,
  hasLoadedWorkspaces: hasLoadedWorkspacesSlice.reducer,
  workspaces: workspacesSlice.reducer,
  groups: groupsSlice.reducer,
  menus: menusSlice.reducer,
  bookmarks: bookmarksSlice.reducer,
}
