import { css } from '@emotion/react'
import { GroupState } from '../../state/reducers/groups-reducer'
import GroupDragHandle from './GroupDragHandle'

export interface ViewGroupHeaderProps {
  group: GroupState
}

const ViewGroupHeader = ({ group }: ViewGroupHeaderProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      `}
      title="Edit group"
    >
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <GroupDragHandle name={group.name} />
      </div>
    </div>
  )
}

export default ViewGroupHeader
