import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { CollectionDocumentV1 } from '../documents/AccountDocumentV1'
import { LocalCollectionDocumentV1 } from '../documents/LocalAccountDocumentV1.js'
import { localstorageState } from '../localstorage'
import { mergeAll } from './custom-actions'
import { addMenuToGroup, removeMenuFromGroup } from './groups-reducer'
import {
  AddBookmarkToMenuAction,
  MoveMenuItemAction,
  RemoveBookmarkFromMenuAction,
  SetIsEditingMenuAction,
  SetMenuNameAction,
} from './menus-actions'
import { TUTORIAL_MENUS } from './tutorial-state'

export interface MenuItem {
  id: string
  type: string
}

export interface MenuState {
  id: string
  name: string
  items: MenuItem[]
  isDragging: boolean
  isEditing: boolean
}

export const menuAdapter = createEntityAdapter<MenuState>({
  selectId: (menu) => menu.id,
})

const defaultMenu: MenuState = {
  id: nanoid(),
  name: '',
  isEditing: false,
  isDragging: false,
  items: [],
}
const initialEmptyState = menuAdapter.getInitialState({})
const initialState = localstorageState
  ? localMenuDocumentsToState(localstorageState.menus)
  : menuDocumentsToState(TUTORIAL_MENUS)

export const menusSlice = createSlice({
  name: 'menus',
  initialState: menuAdapter.addMany(initialEmptyState, initialState),
  reducers: {
    setMenuName(state, action: SetMenuNameAction) {
      const menu = state.entities[action.payload.menuId]

      if (menu) {
        menu.name = action.payload.name
      } else {
        throw new Error(`Could not find menu with id ${action.payload.menuId}`)
      }
    },

    updateMenu(state) {
      return state
    },

    setIsEditingMenu(state, action: SetIsEditingMenuAction) {
      const menu = state.entities[action.payload.menuId]

      if (menu) {
        menu.isEditing = action.payload.isEditing
        return state
      } else {
        throw new Error(`Could not find menu with id ${action.payload.menuId}`)
      }
    },

    moveMenuItem(state, action: MoveMenuItemAction) {
      const { menuId, fromItemIndex, toItemIndex } = action.payload
      const menu = state.entities[menuId] as MenuState
      const removed = menu.items.splice(fromItemIndex, 1)[0]
      menu.items.splice(toItemIndex, 0, removed)

      return state
    },

    addBookmarkToMenu: (state, action: AddBookmarkToMenuAction) => {
      const menu = state.entities[action.payload.menuId] as MenuState

      return menuAdapter.updateOne(state, {
        id: action.payload.menuId,
        changes: {
          items: menu.items.concat([
            {
              id: action.payload.entity.id,
              type: 'bookmark',
            },
          ]),
        },
      })
    },

    removeBookmarkFromMenu: (state, action: RemoveBookmarkFromMenuAction) => {
      const menu = state.entities[action.payload.menuId] as MenuState

      return menuAdapter.updateOne(state, {
        id: action.payload.menuId,
        changes: {
          items: menu.items.filter((item) => item.id !== action.payload.bookmarkId),
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mergeAll, (state, action) => {
        return menuAdapter.setAll(state, menuDocumentsToState(action.payload.menus))
      })
      .addCase(addMenuToGroup, (state, action) => {
        return menuAdapter.addOne(state, action.payload.entity)
      })
      .addCase(removeMenuFromGroup, (state, action) => {
        return menuAdapter.removeOne(state, action.payload.menuId)
      })
  },
})

export const { moveMenuItem, updateMenu, setIsEditingMenu, setMenuName, addBookmarkToMenu, removeBookmarkFromMenu } =
  menusSlice.actions

function menuDocumentsToState(menus: CollectionDocumentV1[]): MenuState[] {
  return menus.map((document) => {
    return {
      ...defaultMenu,
      id: document.id || defaultMenu.id,
      name: document.name || defaultMenu.name,
      items: document.items
        ? document.items.map((item) => ({
            id: item.id,
            type: item.type,
          }))
        : defaultMenu.items,
    }
  })
}
function localMenuDocumentsToState(menus: LocalCollectionDocumentV1[]): MenuState[] {
  return menuDocumentsToState(menus)
}
