import { useEffect, useState } from 'react'
import { LambdaSynchronizerService } from '../service/synchronizer/lambda-synchronizer-service'
import { stateToDocumentV1 } from '../state/documents/AccountDocumentV1'
import { stateToLocalDocumentV1 } from '../state/documents/LocalAccountDocumentV1'
import { saveStateToLocalstorage } from '../state/localstorage'
import { afterChangesHasBeenSaved, afterMergeChangesHasBeenSaved } from '../state/reducers/change-reducer'
import { mergeAll } from '../state/reducers/custom-actions'
import { useAppDispatch, useAppSelector } from '../state/store'

const { initializeRemoteState, getRemoteState, updateRemoteState } = LambdaSynchronizerService()

export const useSynchronizer = () => {
  const dispatch = useAppDispatch()
  const [synchronizing, setSynchronizing] = useState(false)
  const currentState = useAppSelector((state) => state)
  const { isAuthenticated, checkingIfAuthenticated } = currentState.auth
  const { hasUnsavedChanges, hasUnsavedMergeChanges, hasUnsavedLocalstorageChanges } = useAppSelector(
    (state) => state.changes,
  )
  const isCriticalError = useAppSelector((state) => state.isCriticalError)

  useEffect(() => {
    if (isAuthenticated && !checkingIfAuthenticated) {
      setSynchronizing(true)
      getRemoteState((err, document) => {
        if (err && err.status === 404) {
          initializeRemoteState(stateToDocumentV1(currentState), (document) => {
            dispatch(mergeAll(document))
          })
        } else if (document) {
          dispatch(mergeAll(document))
        } else {
          console.error('Unable to synchronize')
          setSynchronizing(false)
        }
      })
    }
  }, [isAuthenticated, checkingIfAuthenticated])

  useEffect(() => {
    if (hasUnsavedMergeChanges) {
      saveStateToLocalstorage(stateToLocalDocumentV1(currentState))
      dispatch(afterMergeChangesHasBeenSaved())
      setSynchronizing(false)
    }
  }, [hasUnsavedMergeChanges])

  useEffect(() => {
    if (isCriticalError) return

    if (hasUnsavedChanges || hasUnsavedLocalstorageChanges) {
      saveStateToLocalstorage(stateToLocalDocumentV1(currentState))
    }

    if (!isAuthenticated) return
    if (!hasUnsavedChanges) return

    setSynchronizing(true)
    updateRemoteState(stateToDocumentV1(currentState), () => {
      setSynchronizing(false)
      dispatch(afterChangesHasBeenSaved())
    })
  }, [isAuthenticated, hasUnsavedChanges, hasUnsavedLocalstorageChanges, isCriticalError])

  return {
    synchronizing,
  }
}
