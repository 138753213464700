import { createSlice } from '@reduxjs/toolkit'

interface DnDState {
  isDraggingBookmark: boolean;
  isDraggingGroup: boolean;
  isMouseInTrashDropzone: boolean;
}

const initialState: DnDState = {
  isDraggingBookmark: false,
  isDraggingGroup: false,
  isMouseInTrashDropzone: false,
}

export const dndSlice = createSlice({
  name: 'dnd',
  initialState,
  reducers: {
    startDragBookmark: (state) => ({
      ...state,
      isDraggingBookmark: true,
    }),
    endDragBookmark: (state) => ({
      ...state,
      isDraggingBookmark: false,
      isMouseInTrashDropzone: false,
    }),
    startDragGroup: (state) => ({
      ...state,
      isDraggingGroup: true,
    }),
    endDragGroup: (state) => ({
      ...state,
      isDraggingGroup: false,
      isMouseInTrashDropzone: false,
    }),
    setIsMouseInTrashDropzone: (state, action) => ({
      ...state,
      isMouseInTrashDropzone: action.payload,
    }),
  },
})

export const { startDragBookmark, endDragBookmark, startDragGroup, endDragGroup, setIsMouseInTrashDropzone } = dndSlice.actions