import { css } from '@emotion/react'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import Login from '@mui/icons-material/Login'
import Edit from '@mui/icons-material/Edit'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Settings from '@mui/icons-material/Settings'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Menu, MenuItem } from '@mui/material'
import { nanoid } from 'nanoid'
import React from 'react'
import { GroupState, removeMenuFromGroup } from '../../state/reducers/groups-reducer'
import { addBookmarkToMenu, MenuState, setIsEditingMenu, setMenuName } from '../../state/reducers/menus-reducer'
import { useAppDispatch, useAppSelector } from '../../state/store'
import { BOOKMARK_OR_MENU_MAX_HEIGHT } from '../bookmark/ViewBookmark'
import { AppMenuLink } from '../buttons/AppMenuLink'
import { AppEditBookmarkMenu } from './AppEditBookmarkMenu'
import { AppViewBookmarkMenu } from './AppViewBookmarkMenu'
import { useAuth } from '../../hooks/use-auth'

export interface AppBookmarkMenuProps {
  group: GroupState
  menuId: string
}

export const AppBookmarkMenu = ({ group, menuId }: AppBookmarkMenuProps) => {
  const dispatch = useAppDispatch()
  const { login } = useAuth()
  const menu = useAppSelector((state) => state.menus.entities[menuId]) as MenuState
  const { id: groupId } = group
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const { id, isEditing, name } = menu
  const [editMenuEl, setEditMenuEl] = React.useState<EventTarget | null>(null)
  const isEditMenuOpen = Boolean(editMenuEl)
  const [expanded, setExpanded] = React.useState<boolean>(false)

  function closeEditMenu(): void {
    return setEditMenuEl(null)
  }

  return isEditing
    ? (
      <div onClick={(e: any) => e.stopPropagation()}>
        <AppEditBookmarkMenu
          initialValues={{ name }}
          onSubmit={(form) => {
            dispatch(
              setMenuName({
                menuId: id,
                name: form.name,
              }),
            )
            dispatch(setIsEditingMenu({ menuId: id, isEditing: false }))
          }}
          onCancel={() => {
            if (!name) {
              dispatch(removeMenuFromGroup({ groupId: group.id, menuId: id }))
            } else {
              dispatch(setIsEditingMenu({ menuId: id, isEditing: false }))
            }
          }}
        />
      </div>
    )
    : (
      <Accordion
        expanded={expanded}
        onChange={(_: any, isExpanded: boolean) => setExpanded(isExpanded)}
        disableGutters={true}
        css={css`
        box-shadow: none;
        &:hover [data-comp='menu-edit-button'] {
          visibility: visible;
        }
      `}
      >
        <AccordionSummary
          expandIcon={isEditing ? <div></div> : <ExpandMore />}
          css={css`
          flex-direction: row-reverse;
          background-color: ${isEditMenuOpen ? 'rgba(25, 118, 210, 0.04)' : 'inherit'};
          padding: 0;
          margin-left: 2px;
          min-height: 0;
          max-height: ${BOOKMARK_OR_MENU_MAX_HEIGHT};
          &:hover {
            background-color: rgba(25, 118, 210, 0.04);
          }
        `}
        >
          <div>
            <span
              css={css`
                margin-left: 6px;
              `}
            >
              {name}
            </span>

            <IconButton
              data-comp="menu-edit-button"
              title="Edit menu name"
              onClick={(e: any) => {
                setEditMenuEl(e.target)
                e.stopPropagation()
              }}
              color="inherit"
              size="small"
              css={css`
                position: absolute;
                right: 0.4rem;
                top: 50%;
                transform: translate(0, -50%);
                visibility: hidden;
              `}
            >
              <Settings style={{ fontSize: '1rem' }} />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <AppViewBookmarkMenu menu={menu} />
        </AccordionDetails>

        <Menu keepMounted anchorEl={editMenuEl as any} open={isEditMenuOpen} onClose={() => closeEditMenu()}>
          {isAuthenticated ? null : (
            <AppMenuLink
              to="/api/login"
              title="Login to edit"
              IconComponent={Login}
              onClick={() => {
                login()
                closeEditMenu()
              }}
            />
          )}
          <MenuItem
            disabled={!isAuthenticated}
            onClick={() => {
              closeEditMenu()
              setExpanded(true)
              dispatch(
                addBookmarkToMenu({
                  menuId: menu.id,
                  entity: {
                    id: nanoid(),
                    isEditing: true,
                    faviconFailures: 0,
                    name: '',
                    uri: '',
                  },
                }),
              )
            }}
          >
            <Add
              color="primary"
              css={css`
              margin-right: 1rem;
            `}
            />
            <span>Add bookmark to menu</span>
          </MenuItem>

          <MenuItem
            disabled={!isAuthenticated}
            onClick={() => {
              closeEditMenu()
              dispatch(
                setIsEditingMenu({
                  menuId: menu.id,
                  isEditing: true,
                }),
              )
            }}
          >
            <Edit
              color="primary"
              css={css`
              margin-right: 1rem;
            `}
            />
            <span>Edit menu name</span>
          </MenuItem>

          <MenuItem
            disabled={!isAuthenticated}
            onClick={() => {
              closeEditMenu()
              dispatch(
                removeMenuFromGroup({
                  groupId: groupId,
                  menuId: menu.id,
                }),
              )
            }}
          >
            <Delete
              color="secondary"
              css={css`
              margin-right: 1rem;
            `}
            />
            <span>Delete Menu</span>
          </MenuItem>
        </Menu>
      </Accordion>
    )
}
