import { environment } from '../../environment'
import { AccountDocumentV1 } from '../../state/documents/AccountDocumentV1.js'

export interface AwsLambdaService {
  getAccount(): Promise<AccountDocumentV1>
  postAccount(account: AccountDocumentV1): Promise<AccountDocumentV1>
  putAccount(account: AccountDocumentV1): Promise<AccountDocumentV1>
}

export default (): AwsLambdaService => {
  const defaultHeaders = {
    'Content-type': 'application/json',
  }

  return {
    async getAccount(): Promise<AccountDocumentV1> {
      return fetch(`${environment.synchronizer.api}/account`, {
        headers: defaultHeaders,
        method: 'GET',
      }).then((res) => {
        if (!res.ok) {
          throw { status: res.status }
        }
        return res.json()
      })
    },

    async postAccount(account: AccountDocumentV1): Promise<AccountDocumentV1> {
      return fetch(`${environment.synchronizer.api}/account`, {
        headers: defaultHeaders,
        method: 'POST',
        body: JSON.stringify(account),
      }).then((res) => res.json())
    },

    async putAccount(account: AccountDocumentV1): Promise<AccountDocumentV1> {
      return fetch(`${environment.synchronizer.api}/account`, {
        headers: defaultHeaders,
        method: 'PUT',
        body: JSON.stringify(account),
      }).then((res) => res.json())
    },
  }
}
