import { css } from '@emotion/react'
import { Field, Form } from 'react-final-form'
import { requiredValidator } from '../../validators/required-validator'
import { combineValidators } from '../../validators/validator-tools'
import { ApproveButton, CancelButton } from '../buttons/buttons'
import FormTextField from '../form/FormTextField'
import SpacedActions from '../spaced-actions/SpacedActions'

export interface EditGroupHeader {
  initialValues: Record<string, any>
  onSubmit: (values: Record<string, any>) => void
  onCancel: () => void
}

export default ({ initialValues, onSubmit, onCancel }: EditGroupHeader) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      render={({ handleSubmit, form, invalid }) => (
        <form
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
          `}
          onSubmit={handleSubmit}
        >
          <Field
            name="name"
            component={FormTextField}
            validate={combineValidators(requiredValidator)}
            componentProps={{
              css: css`
                width: 100%;
                &:hover $nameOutline: {
                  border-color: theme.palette.primary.contrastText;
                },
              `,
              InputProps: {
                classes: {
                  input: css`
                    color: theme.palette.primary.contrastText;
                    &:focus $nameInputLabel: {
                      color: theme.palette.grey['100'];
                    },
                  `,
                  notchedOutline: css`
                    border-color: theme.palette.grey['500'];
                    &.Mui-focused: {
                      'border-color': theme.palette.primary.contrastText;
                    },
                  `,
                },
              },
              InputLabelProps: {
                css: css`
                color: theme.palette.grey['400'];
                &.Mui-focused: {
                  color: theme.palette.primary.contrastText;
                },
              `,
              },
              label: 'Name',
              color: 'primary',
              variant: 'outlined',
            }}
          ></Field>

          <SpacedActions>
            <CancelButton
              buttonProps={{
                color: 'secondary',
                title: 'Cancel',
                onClick: () => {
                  form.reset()
                  onCancel()
                },
              }}
            ></CancelButton>

            <ApproveButton
              buttonProps={{
                type: 'submit',
                css: css``, // theme.palette.common.white
                color: 'inherit',
                disabled: invalid,
                title: 'Save changes',
              }}
              iconProps={{
                color: 'primary',
              }}
            ></ApproveButton>
          </SpacedActions>
        </form>
      )}
    ></Form>
  )
}
