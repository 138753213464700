import { css } from '@emotion/react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import Login from '@mui/icons-material/Login'
import Settings from '@mui/icons-material/Settings'
import { Button, IconButton, Menu, MenuItem } from '@mui/material'
import React, { MouseEvent, useState } from 'react'
import { useAuth } from '../../hooks/use-auth'
import { BookmarkState, setFaviconErrors, setIsEditingBookmark } from '../../state/reducers/bookmarks-reducer'
import { removeBookmarkFromGroup } from '../../state/reducers/groups-reducer'
import { removeBookmarkFromMenu } from '../../state/reducers/menus-reducer'
import { useAppDispatch, useAppSelector } from '../../state/store'
import { AppMenuLink } from '../buttons/AppMenuLink'

export const BOOKMARK_OR_MENU_MAX_HEIGHT = '36px'

export interface ViewBookmarkProps {
  bookmarkId: string
  menuId?: string
  groupId?: string
}

export default ({ bookmarkId, menuId, groupId }: ViewBookmarkProps) => {
  const { login } = useAuth()
  const [iconError, setIconError] = useState(false)
  const [editMenuEl, setEditMenuEl] = React.useState<EventTarget | null>(null)
  const isEditMenuOpen = Boolean(editMenuEl)
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const bookmark = useAppSelector((state) => state.bookmarks.entities[bookmarkId]) as BookmarkState
  const parsed = new URL(bookmark.uri)

  function closeEditMenu() {
    setEditMenuEl(null)
  }

  function onClickEdit(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    setEditMenuEl(e.currentTarget)
  }

  return (
    <div
      css={css`
        position: relative;
        margin: 0;
        width: 100%;
        &:hover [data-comp='edit-button'] {
          visibility: visible;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 0.4rem;
          top: 50%;
          transform: translate(0, -50%);
          width: 1rem;
        `}
      >
        {bookmark.faviconFailures < 3 && !iconError ? (
          <img
            css={css`
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              width: 100%;
            `}
            src={`${parsed.origin}/favicon.ico`}
            loading="lazy"
            alt=""
            onLoad={() =>
              bookmark.faviconFailures > 0
                ? dispatch(
                    setFaviconErrors({
                      bookmarkId: bookmark.id,
                      faviconFailures: 0,
                    }),
                  )
                : null
            }
            onError={() => {
              setIconError(true)
              dispatch(
                setFaviconErrors({
                  bookmarkId: bookmark.id,
                  faviconFailures: bookmark.faviconFailures + 1,
                }),
              )
            }}
          />
        ) : (
          <BookmarkIcon
            color="primary"
            css={css`
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              width: 100%;
            `}
          />
        )}
      </div>

      <Button
        css={css`
          justify-content: start;
          padding-left: 2rem;
          width: 100%;
          max-height: ${BOOKMARK_OR_MENU_MAX_HEIGHT};
          background-color: ${isEditMenuOpen ? 'rgba(25, 118, 210, 0.04)' : 'inherit'};
        `}
        href={parsed.href}
      >
        {bookmark.name}
      </Button>

      <IconButton
        data-comp="edit-button"
        title="Edit bookmark"
        onClick={(e: MouseEvent) => onClickEdit(e)}
        color="inherit"
        size="small"
        css={css`
          position: absolute;
          right: 0.4rem;
          top: 50%;
          transform: translate(0, -50%);
          visibility: hidden;
        `}
      >
        <Settings style={{ fontSize: '1rem' }} />
      </IconButton>

      <Menu keepMounted anchorEl={editMenuEl as any} open={isEditMenuOpen} onClose={() => closeEditMenu()}>
        {isAuthenticated ? null : (
          <AppMenuLink
            to="/api/login"
            title="Login to edit"
            IconComponent={Login}
            onClick={() => {
              login()
              closeEditMenu()
            }}
          />
        )}

        <MenuItem
          disabled={!isAuthenticated}
          onClick={() => {
            closeEditMenu()
            dispatch(setIsEditingBookmark({ bookmarkId: bookmark.id, isEditing: true }))
          }}
        >
          <Edit
            color="primary"
            css={css`
              margin-right: 1rem;
            `}
          />
          <span>Edit bookmark</span>
        </MenuItem>

        <MenuItem
          disabled={!isAuthenticated}
          onClick={() => {
            closeEditMenu()
            if (menuId) {
              dispatch(
                removeBookmarkFromMenu({
                  menuId,
                  bookmarkId,
                }),
              )
            } else if (groupId) {
              dispatch(
                removeBookmarkFromGroup({
                  groupId,
                  bookmarkId,
                }),
              )
            } else {
              throw new Error('Cant delete bookmark, no id provided')
            }
          }}
        >
          <Delete
            color="secondary"
            css={css`
              margin-right: 1rem;
            `}
          />
          <span>Delete Bookmark</span>
        </MenuItem>
      </Menu>
    </div>
  )
}
