const { REACT_APP_SYNCHRONIZER_API = `${window.origin}/api`, NODE_ENV = 'development' } = process.env

export const environment: Environment = {
  synchronizer: {
    api: REACT_APP_SYNCHRONIZER_API,
  },
  production: NODE_ENV === 'production',
}

export interface Environment {
  synchronizer: {
    api: string
  }
  production: boolean
}
