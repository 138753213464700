import { css } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material'
import ErrorBoundary from './components/error/ErrorBoundary'
import Workspaces from './components/workspaces/Workspaces'
import { selectIsDraggingAnything } from './state/reducers/dnd-selectors'
import { useAppSelector } from './state/store'

const theme = createTheme({})

interface AppProps {}

export default ({}: AppProps) => {
  const isDraggingAnything = useAppSelector((state) => selectIsDraggingAnything(state))
  console.log(isDraggingAnything)

  return (
    <ThemeProvider theme={theme}>
      <div
        css={css`
          overflow: ${isDraggingAnything ? 'hidden' : 'auto'};
          height: 100vh;
        `}
      >
        <ErrorBoundary>
          <Workspaces />
        </ErrorBoundary>
      </div>
    </ThemeProvider>
  )
}
