import Add from '@mui/icons-material/Add'
import { Button, css } from '@mui/material'
import { nanoid } from 'nanoid'
import { useDispatch } from 'react-redux'
import { addBookmarkToMenu, MenuState } from '../../state/reducers/menus-reducer'
import { useAppSelector } from '../../state/store'
import BookmarkComponent from '../bookmark/BookmarkComponent'

export interface AppViewMenuProps {
  menu: MenuState
}

export const AppViewBookmarkMenu = ({ menu }: AppViewMenuProps) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  return menu.items.length === 0 ? (
    <div>
      <Button
        disabled={!isAuthenticated}
        onClick={() => {
          dispatch(
            addBookmarkToMenu({
              menuId: menu.id,
              entity: {
                id: nanoid(),
                isEditing: true,
                faviconFailures: 0,
                name: '',
                uri: '',
              },
            }),
          )
        }}
      >
        <Add
          color="primary"
          css={css`
              margin-right: 1rem;
            `}
        />
        <span css={css`
              text-transform: none;
              `}>
          Add bookmark to menu
        </span>
      </Button>
    </div>
  ) : (
    <div>
      {
        menu.items.map((item) => (
          <BookmarkComponent menu={menu} bookmarkId={item.id} key={item.id} />
        ))
      }
    </div>
  )
}
