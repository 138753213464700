import { css } from '@emotion/react'

export interface SpacedActionsProps {
  children: JSX.Element[]
}

export default ({ children }: SpacedActionsProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin: 1rem auto 0.6rem;
        width: 85%;
      `}
    >
      {children}
    </div>
  )
}
