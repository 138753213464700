import { useDispatch } from 'react-redux'
import { BookmarkState, setIsEditingBookmark, updateBookmark } from '../../state/reducers/bookmarks-reducer'
import { GroupState, removeBookmarkFromGroup } from '../../state/reducers/groups-reducer'
import { MenuState, removeBookmarkFromMenu } from '../../state/reducers/menus-reducer'
import { useAppSelector } from '../../state/store'
import EditBookmark from './EditBookmark'
import ViewBookmark from './ViewBookmark'
import { css } from '@emotion/react'

const ensureSchema = (uri: string) => {
  try {
    new URL(uri)
    return uri
  } catch (e) {
    return `https://${uri}`
  }
}

export interface BookmarkComponentProps {
  group?: GroupState
  menu?: MenuState
  bookmarkId: string
}

export default ({ group, menu, bookmarkId }: BookmarkComponentProps) => {
  const dispatch = useDispatch()
  const bookmark = useAppSelector((state) => state.bookmarks.entities[bookmarkId]) as BookmarkState
  const { name, uri } = bookmark

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {bookmark.isEditing ? (
        <div>

          <EditBookmark
            initialValues={{ name, uri }}
            onSubmit={(form) => {
              dispatch(
                updateBookmark({
                  id: bookmark.id,
                  changes: {
                    name: form.name,
                    isEditing: false,
                    uri: ensureSchema(form.uri),
                    faviconFailures: 0,
                  },
                }),
              )
              setIsEditingBookmark({ bookmarkId: bookmark.id, isEditing: false })
            }}
            onCancel={() => {
              if (!name || !uri) {
                if (group) {
                  dispatch(removeBookmarkFromGroup({ groupId: group.id, bookmarkId: bookmark.id }))
                } else if (menu) {
                  dispatch(removeBookmarkFromMenu({ menuId: menu.id, bookmarkId: bookmark.id }))
                }
              } else {
                dispatch(setIsEditingBookmark({ bookmarkId: bookmark.id, isEditing: false }))
              }
            }}
          />
        </div>
      ) : (
        <ViewBookmark bookmarkId={bookmark.id} menuId={menu?.id} groupId={group?.id} />
      )}
    </div>
  )
}
