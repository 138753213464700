import { css } from '@emotion/react'

import { GroupState } from '../../state/reducers/groups-reducer'
import GroupComponent from '../group/GroupComponent'

export const groupStyles = {}

export interface SortableGroupProps {
  group: GroupState
  i: number
}

export default ({ group, i }: SortableGroupProps) => {
  return (
    <div
      css={css`
        margin: 1rem;
        vertical-align: top;
      `}
    >
      <GroupComponent group={group} index={i}></GroupComponent>
    </div>
  )
}
