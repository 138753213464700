import { css } from '@emotion/react'

import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { endDragBookmark, endDragGroup, startDragBookmark, startDragGroup } from '../../state/reducers/dnd-reducer'
import {
  GroupState,
  moveGroupItem,
  moveGroupItemToAnotherGroup,
  removeBookmarkFromGroup,
} from '../../state/reducers/groups-reducer'
import { selectActiveWorkspace } from '../../state/reducers/root-selectors'
import { moveGroup, removeGroupFromWorkspace } from '../../state/reducers/workspaces-reducer'
import { useAppSelector } from '../../state/store'
import SortableGroups from './SortableGroups'

const getGroupIdFromDroppableId = (droppableId: string) => {
  return parseInt(droppableId.split('group-')[1], 10)
}

export interface WorkspaceComponentProps {}

export default ({}: WorkspaceComponentProps) => {
  const activeWorkspace = useAppSelector((state) => selectActiveWorkspace(state))
  const allGroups = useAppSelector((state) => state.groups)

  const dispatch = useDispatch()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onSortGroupsStart() {
    dispatch(startDragGroup())
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onSortGroupsEnd({ oldIndex: sourceIndex, newIndex: destinationIndex }: any, e: any) {
    const groupId = activeWorkspace.items[sourceIndex].id

    dispatch(endDragGroup())

    if ((e.target as any).id === 'trash-dropzone') {
      dispatch(removeGroupFromWorkspace({ workspaceId: activeWorkspace.id, groupId }))
    } else if (sourceIndex !== destinationIndex) {
      dispatch(
        moveGroup({
          workspaceId: activeWorkspace.id,
          from: sourceIndex,
          to: destinationIndex,
        }),
      )
    }
  }

  function onDragBookmarkStart() {
    dispatch(startDragBookmark())
  }

  function onDragBookmarkEnd({ source, destination }: DropResult) {
    dispatch(endDragBookmark())

    if (!destination) {
      return
    }

    const sourceGroupIndex = getGroupIdFromDroppableId(source.droppableId)
    const targetGroupIndex = getGroupIdFromDroppableId(destination.droppableId)
    const { index: fromItemIndex } = source
    const { index: toItemIndex } = destination
    const fromGroupId = activeWorkspace.items[sourceGroupIndex].id

    if (destination.droppableId === 'trash') {
      const sourceGroup = allGroups.entities[fromGroupId] as GroupState
      const bookmarkId = sourceGroup.items[fromItemIndex].id
      dispatch(
        removeBookmarkFromGroup({
          groupId: fromGroupId,
          bookmarkId,
        }),
      )
    } else if (sourceGroupIndex !== targetGroupIndex) {
      const toGroupId = activeWorkspace.items[targetGroupIndex].id

      dispatch(
        moveGroupItemToAnotherGroup({
          fromGroupId,
          toGroupId,
          fromItemIndex,
          toItemIndex,
        }),
      )
    } else if (fromItemIndex !== toItemIndex) {
      dispatch(
        moveGroupItem({
          groupId: fromGroupId,
          fromItemIndex,
          toItemIndex,
        }),
      )
    }
  }

  return (
    <div
      css={css`
        min-height: 90%;
        @media (max-width: 768px): {
          group: {
            margin: 0.5rem auto;
            width: 98%;
          },
        },
        a: {
          text-decoration: none;
          &:visited: {
            color: inherit;
          };
        };
      `}
    >
      <DragDropContext
        onDragStart={() => onDragBookmarkStart()}
        onDragEnd={(result: DropResult) => onDragBookmarkEnd(result)}
      >
        {/* <WorkspaceActionMenu /> */}

        {/* <SortableGroups
          axis="xy"
          useDragHandle={true}
          updateBeforeSortStart={() => onSortGroupsStart()}
          onSortEnd={(sortEnd: SortEnd, e: SortEvent) => onSortGroupsEnd(sortEnd, e)}
        /> */}

        <SortableGroups />
      </DragDropContext>
    </div>
  )
}
