import { BookmarkState } from '../reducers/bookmarks-reducer'
import { GroupState } from '../reducers/groups-reducer'
import { WorkspaceState } from '../reducers/workspaces-reducer'
import { RootState } from '../store'
import { RootDocument } from './RootDocument'

export const STATE_VERSION_1 = 1

export interface LocalAccountDocumentV1 extends RootDocument {
  activeWorkspace: string
  workspaces: LocalCollectionDocumentV1[]
  groups: LocalCollectionDocumentV1[]
  menus: LocalCollectionDocumentV1[]
  bookmarks: LocalBookmarkDocumentV1[]
}

export interface LocalCollectionDocumentV1 {
  id: string
  name: string
  items: {
    id: string
    type: string
  }[]
}

export interface LocalBookmarkDocumentV1 {
  id: string
  name: string
  uri: string
  faviconFailures: number
}

export function stateToLocalDocumentV1(state: RootState): LocalAccountDocumentV1 {
  return {
    version: STATE_VERSION_1,
    lastUpdated: state.changes.lastUpdated.toISOString(),
    activeWorkspace: state.activeWorkspace,
    workspaces: state.workspaces.ids
      .map((id) => state.workspaces.entities[id] as WorkspaceState)
      .map((entity) => ({
        id: entity.id,
        name: entity.name,
        items: entity.items.map((item) => ({
          id: item.id,
          type: item.type,
        })),
      })),
    groups: state.groups.ids
      .map((id) => state.groups.entities[id] as GroupState)
      .map((entity) => ({
        id: entity.id,
        name: entity.name,
        items: entity.items.map((item) => ({
          id: item.id,
          type: item.type,
        })),
      })),
    menus: state.menus.ids
      .map((id) => state.menus.entities[id] as GroupState)
      .map((entity) => ({
        id: entity.id,
        name: entity.name,
        items: entity.items.map((item) => ({
          id: item.id,
          type: item.type,
        })),
      })),
    bookmarks: state.bookmarks.ids
      .map((id) => state.bookmarks.entities[id] as BookmarkState)
      .map((entity) => ({
        id: entity.id,
        name: entity.name,
        uri: entity.uri,
        faviconFailures: entity.faviconFailures,
      })),
  }
}
