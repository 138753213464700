import { createSlice } from '@reduxjs/toolkit'
import { setFaviconErrors, updateBookmark } from './bookmarks-reducer'
import { mergeAll } from './custom-actions'
import {
  moveGroupItem,
  moveGroupItemToAnotherGroup,
  removeBookmarkFromGroup,
  removeMenuFromGroup,
  setGroupName,
} from './groups-reducer'
import { removeBookmarkFromMenu, setMenuName } from './menus-reducer'
import { moveGroup, removeGroupFromWorkspace } from './workspaces-reducer'

export interface ChangesState {
  hasUnsavedChanges: boolean
  hasUnsavedMergeChanges: boolean
  hasUnsavedLocalstorageChanges: boolean
  lastUpdated: Date
}

const initialState: ChangesState = {
  hasUnsavedChanges: false,
  hasUnsavedMergeChanges: false,
  hasUnsavedLocalstorageChanges: false,
  lastUpdated: new Date(),
}
export const changesSlice = createSlice({
  name: 'changes',
  initialState,
  reducers: {
    afterChangesHasBeenSaved: (state) => ({
      ...state,
      hasUnsavedChanges: false,
    }),
    afterMergeChangesHasBeenSaved: (state) => ({
      ...state,
      hasUnsavedMergeChanges: false,
    }),
  },
  extraReducers: (builder) => {
    const prepareToSave = (): ChangesState => ({
      hasUnsavedChanges: true,
      hasUnsavedMergeChanges: false,
      hasUnsavedLocalstorageChanges: false,
      lastUpdated: new Date(),
    })
    const prepareToSaveToLocalstorage = (): ChangesState => ({
      hasUnsavedChanges: false,
      hasUnsavedMergeChanges: false,
      hasUnsavedLocalstorageChanges: true,
      lastUpdated: new Date(),
    })
    builder
      .addCase(updateBookmark, prepareToSave)
      .addCase(setFaviconErrors, prepareToSaveToLocalstorage)
      .addCase(removeMenuFromGroup, prepareToSave)
      .addCase(removeGroupFromWorkspace, prepareToSave)
      .addCase(removeBookmarkFromGroup, prepareToSave)
      .addCase(removeBookmarkFromMenu, prepareToSave)
      .addCase(setGroupName, prepareToSave)
      .addCase(setMenuName, prepareToSave)
      .addCase(moveGroup, prepareToSave)
      .addCase(moveGroupItem, prepareToSave)
      .addCase(moveGroupItemToAnotherGroup, prepareToSave)
      .addCase(mergeAll, (state) => ({
        ...state,
        hasUnsavedMergeChanges: true,
      }))
  },
})

export const { afterChangesHasBeenSaved, afterMergeChangesHasBeenSaved } = changesSlice.actions
