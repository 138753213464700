import { css } from '@emotion/react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Add from '@mui/icons-material/Add'
import { AppBar, Button, IconButton, Toolbar } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'
import { addGroupToWorkspace } from '../../state/reducers/workspaces-reducer'
import { useAppSelector } from '../../state/store'
import MainMenu from './MainMenu'

export interface WorkspacesHeader {}

export default ({}: WorkspacesHeader) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [mainMenuEl, setMainMenuEl] = React.useState<EventTarget | null>(null)
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const workspaceId = useAppSelector((state) => state.activeWorkspace)
  const { login } = useAuth()

  return (
    <AppBar position="static">
      <Toolbar>
        {isAuthenticated ? null : (
          <Button color="inherit" href="/api/login" title="Login to edit" onClick={() => login()}>
            Login to Edit
          </Button>
        )}

        {!isAuthenticated || location.pathname !== '/' ? null : (
          <IconButton
            color="inherit"
            title="Add group"
            onClick={() => {
              dispatch(
                addGroupToWorkspace({
                  workspaceId,
                  entity: {
                    id: nanoid(),
                    name: '',
                    items: [],
                    isDragging: false,
                    isEditing: true,
                  },
                }),
              )
            }}
          >
            <Add />
          </IconButton>
        )}

        <div
          css={css`
            flex-grow: 1;
          `}
        ></div>

        {!isAuthenticated ? null : (
          <div>
            <IconButton
              color="inherit"
              title="Navigation menu"
              onClick={(e: MouseEvent) => setMainMenuEl(e.currentTarget)}
            >
              <AccountCircle />
            </IconButton>
            <MainMenu anchorEl={mainMenuEl} open={Boolean(mainMenuEl)} onClose={() => setMainMenuEl(null)} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}
