
import { GroupItem, GroupState } from '../../state/reducers/groups-reducer'
import { AppBookmarkMenu } from '../bookmark-menu/AppBookmarkMenu'
import BookmarkComponent from '../bookmark/BookmarkComponent'


export interface GroupItemProps {
  group: GroupState;
  item: GroupItem;
}

export const AppGroupItem = ({ item, group }: GroupItemProps) => {
  switch (item.type) {
    case 'bookmark': return <BookmarkComponent group={group} bookmarkId={item.id} />
    case 'menu': return <AppBookmarkMenu group={group} menuId={item.id} />
    default: return null
  }
}