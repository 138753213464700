import EmojiPeople from '@mui/icons-material/EmojiPeople'
import Feedback from '@mui/icons-material/Feedback'
import Home from '@mui/icons-material/Home'
import Info from '@mui/icons-material/Info'
import Policy from '@mui/icons-material/Policy'
import QuestionAnswer from '@mui/icons-material/QuestionAnswer'
import { Menu } from '@mui/material'
import { AppMenuLink } from '../buttons/AppMenuLink'

export interface MainMenuProps {
  anchorEl: any
  open: boolean
  onClose: () => void
}

export default ({ anchorEl, open, onClose }: MainMenuProps) => {
  const links = [
    {
      to: '/',
      title: 'Home',
      iconComponent: Home,
    },
    {
      to: '/faq',
      title: 'FAQ',
      iconComponent: QuestionAnswer,
    },
    {
      to: '/privacy',
      title: 'Privacy Policy',
      iconComponent: Policy,
    },
    {
      to: '/feedback',
      title: 'Got feedback?',
      iconComponent: Feedback,
    },
    {
      to: '/about',
      title: 'About',
      iconComponent: Info,
    },
    {
      href: '/api/logout',
      title: 'Logout',
      iconComponent: EmojiPeople,
    },
  ]

  return (
    <Menu keepMounted anchorEl={anchorEl} open={open} onClose={onClose}>
      {links.map(({ to, title, iconComponent, href }, i) => (
        <AppMenuLink key={i} to={to} href={href} title={title} onClick={onClose} IconComponent={iconComponent} />
      ))}
    </Menu>
  )
}
