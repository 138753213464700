import { nanoid } from 'nanoid'
import { BookmarkDocumentV1, CollectionDocumentV1 } from '../documents/AccountDocumentV1.js'

export const TUTORIAL_WORKSPACE_ID = nanoid()
const TUTORIAL_GROUP_1_ID = nanoid()
const TUTORIAL_GROUP_2_ID = nanoid()
const TUTORIAL_MENU_1_ID = nanoid()
const TUTORIAL_MENU_2_ID = nanoid()
const TUTORIAL_BOOKMARK_1_ID = nanoid()
const TUTORIAL_BOOKMARK_2_ID = nanoid()
const TUTORIAL_BOOKMARK_3_ID = nanoid()
const TUTORIAL_BOOKMARK_4_ID = nanoid()
const TUTORIAL_BOOKMARK_5_ID = nanoid()
const TUTORIAL_BOOKMARK_6_ID = nanoid()

export const TUTORIAL_WORKSPACES: CollectionDocumentV1[] = [
  {
    id: TUTORIAL_WORKSPACE_ID,
    name: 'Default workspace',
    items: [
      {
        id: TUTORIAL_GROUP_1_ID,
        type: 'group',
      },
      {
        id: TUTORIAL_GROUP_2_ID,
        type: 'group',
      },
    ],
  },
]

export const TUTORIAL_GROUPS: CollectionDocumentV1[] = [
  {
    id: TUTORIAL_GROUP_1_ID,
    name: 'Memes',
    items: [
      {
        id: TUTORIAL_MENU_1_ID,
        type: 'menu',
      },
      {
        id: TUTORIAL_MENU_2_ID,
        type: 'menu',
      },
    ],
  },
  {
    id: TUTORIAL_GROUP_2_ID,
    name: 'Socials',
    items: [
      {
        id: TUTORIAL_BOOKMARK_1_ID,
        type: 'bookmark',
      },
      {
        id: TUTORIAL_BOOKMARK_2_ID,
        type: 'bookmark',
      },
    ],
  },
]

export const TUTORIAL_MENUS: CollectionDocumentV1[] = [
  {
    id: TUTORIAL_MENU_1_ID,
    name: 'Cat memes',
    items: [
      {
        id: TUTORIAL_BOOKMARK_3_ID,
        type: 'bookmark',
      },
      {
        id: TUTORIAL_BOOKMARK_4_ID,
        type: 'bookmark',
      },
    ],
  },
  {
    id: TUTORIAL_MENU_2_ID,
    name: 'Dog memes',
    items: [
      {
        id: TUTORIAL_BOOKMARK_5_ID,
        type: 'bookmark',
      },
      {
        id: TUTORIAL_BOOKMARK_6_ID,
        type: 'bookmark',
      },
    ],
  },
]

export const TUTORIAL_BOOKMARKS: BookmarkDocumentV1[] = [
  {
    id: TUTORIAL_BOOKMARK_1_ID,
    name: 'Instagram',
    uri: 'https://www.instagram.com/',
  },
  {
    id: TUTORIAL_BOOKMARK_2_ID,
    name: 'Linkedin',
    uri: 'https://linkedin.com',
  },
  {
    id: TUTORIAL_BOOKMARK_3_ID,
    name: 'Cat loading',
    uri: 'https://cdn.memes.com/up/25264351566558732/i/1566592941571.jpg',
  },
  {
    id: TUTORIAL_BOOKMARK_4_ID,
    name: 'Cat-a-lac',
    uri: 'https://cdn.memes.com/up/45719851541079132/i/1552057231752.jpg',
  },
  {
    id: TUTORIAL_BOOKMARK_5_ID,
    name: 'Hi from dog',
    uri: 'https://cdn.memes.com/up/54405911548351249/i/1548410895578.jpg',
  },
  {
    id: TUTORIAL_BOOKMARK_6_ID,
    name: 'Doge',
    uri: 'https://cdn.memes.com/up/68349431544419959/i/9973494514125291.jpg',
  },
]
