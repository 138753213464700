import { css } from '@emotion/react'
import Info from './Info'

export default () => (
  <Info header="About us">
    <p>I am a software developer who likes to build websites on my free-time!</p>
    <p>
      I own a small company:{' '}
      <a href="https://data.brreg.no/enhetsregisteret/oppslag/enheter/929586204">Jørgensen Software - 929 586 204</a>
    </p>
    <br />
    <div
      css={css`
        text-align: left;
      `}
    >
      <h2>Attributions</h2>
      <ul>
        <li>
          <a href="https://www.flaticon.com/authors/anggara">Logo created by Anggara - Flaticon</a>
        </li>
      </ul>
    </div>
  </Info>
)
