import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from '../info/About'
import ProgressBar from '../progress-bar/ProgressBar'
import Workspace from '../workspace/WorkspaceComponent'
import WorkspacesHeader from './WorkspacesHeader'

const FAQ = React.lazy(() => import('../info/FAQ'))
const Feedback = React.lazy(() => import('../info/Feedback'))
const Privacy = React.lazy(() => import('../info/Privacy'))

export interface WorkspacesProps {}

export default ({}: WorkspacesProps) => {
  return (
    <BrowserRouter>
      <WorkspacesHeader />
      <ProgressBar />

      <Suspense fallback={<div></div>}>
        <Routes>
          {/* Remember to update sitemap in asset-generator.js if you add more links. */}
          <Route path="/" element={<Workspace />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/feedback" element={<Feedback />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/about" element={<About />}></Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
