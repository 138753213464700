import { css } from '@emotion/react'
import { SvgIconComponent } from '@mui/icons-material'
import { MenuItem, Link as MuiLink } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

interface AppMenuLinkProps {
  to?: string
  href?: string
  title: string
  onClick: () => void
  IconComponent: SvgIconComponent
  externalLink?: boolean
}

export const AppMenuLink: React.FC<AppMenuLinkProps> = ({ to, href, title, onClick, IconComponent }) => {
  const navigate = useNavigate()
  return (
    <MenuItem
      onClick={() => {
        onClick()
        to ? navigate(to) : (window.location.href = href as string)
      }}
    >
      <MuiLink
        component={href ? 'a' : Link}
        href={href ? href : undefined}
        to={to ? to : undefined}
        css={css`
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          text-decoration: none;
        `}
      >
        <IconComponent
          color="primary"
          css={css`
            margin-right: 1rem;
          `}
        ></IconComponent>
        <span>{title}</span>
      </MuiLink>
    </MenuItem>
  )
}
