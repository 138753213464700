// const useStyles = makeStyles({
//   dragHandle: ({ isDraggingGroup }: StyleProps) => ({
//     cursor: isDraggingGroup ? 'grabbing' : 'grab',
//     pointerEvents: 'auto',
//   }),
// })

export interface GroupDragHandleProps {
  name: string
}

export default ({ name }: GroupDragHandleProps) => <div>{name}</div>
