import AccountCircle from '@mui/icons-material/AccountCircle'
import Add from '@mui/icons-material/Add'
import GroupAdd from '@mui/icons-material/AddBoxOutlined'
import Clear from '@mui/icons-material/Clear'
import Delete from '@mui/icons-material/Delete'
import Done from '@mui/icons-material/Done'
import Edit from '@mui/icons-material/Edit'
import Menu from '@mui/icons-material/Menu'
import { SvgIconProps } from '@mui/material'
import Fab from '@mui/material/Fab'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

export interface ButtonProps {
  buttonProps?: IconButtonProps
  iconProps?: SvgIconProps
}

export const EditButton = ({ buttonProps }: ButtonProps) => (
  <IconButton color="inherit" size="small" {...buttonProps}>
    <Edit style={{ fontSize: '1rem' }} />
  </IconButton>
)

export const AccountButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <IconButton {...buttonProps}>
    <AccountCircle {...iconProps} />
  </IconButton>
)

export const MenuButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <IconButton {...buttonProps}>
    <Menu {...iconProps}></Menu>
  </IconButton>
)

export const ApproveButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <Fab {...buttonProps}>
    <Done {...iconProps} />
  </Fab>
)

export const CancelButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <Fab {...buttonProps}>
    <Clear {...iconProps} />
  </Fab>
)

export const AddGroupButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <Fab {...buttonProps}>
    <GroupAdd {...iconProps} />
  </Fab>
)

export const AddButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <Fab {...buttonProps}>
    <Add {...iconProps} />
  </Fab>
)

export const TrashButton = ({ buttonProps, iconProps }: ButtonProps) => (
  <Fab {...buttonProps}>
    <Delete {...iconProps}></Delete>
  </Fab>
)
