import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { CollectionDocumentV1 } from '../documents/AccountDocumentV1'
import { LocalCollectionDocumentV1 } from '../documents/LocalAccountDocumentV1.js'
import { localstorageState } from '../localstorage'
import { mergeAll } from './custom-actions'
import { MergeAllAction } from './root-actions'
import { TUTORIAL_WORKSPACES } from './tutorial-state'
import {
  AddGroupToWorkspaceAction,
  MoveGroupAction,
  RemoveGroupFromWorkspaceAction,
  SetIsEditingWorkspaceAction,
  SetWorkspaceNameAction,
} from './workspaces-actions'

export interface WorkspaceItem {
  id: string
  type: string
}

export interface WorkspaceState {
  id: string
  name: string
  items: WorkspaceItem[]
  isEditing: boolean
}

export const workspacesAdapter = createEntityAdapter<WorkspaceState>({
  selectId: (workspace) => workspace.id,
})

const defaultWorkspace = {
  id: nanoid(),
  name: 'Default workspace',
  isEditing: false,
  items: [],
}
const emptyInitialState = workspacesAdapter.getInitialState({})
const initialWorkspaceState: WorkspaceState[] = localstorageState
  ? localWorkspaceDocumentsToState(localstorageState.workspaces)
  : workspaceDocumentsToState(TUTORIAL_WORKSPACES)

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState: workspacesAdapter.addMany(emptyInitialState, initialWorkspaceState),
  reducers: {
    addWorkspace: workspacesAdapter.addOne,
    removeWorkspace: workspacesAdapter.removeOne,
    updateWorkspace: workspacesAdapter.updateOne,
    setAllWorkspaces: workspacesAdapter.setAll,
    moveGroup: (state, action: MoveGroupAction) => {
      const workspace = state.entities[action.payload.workspaceId] as WorkspaceState
      const removed = workspace.items.splice(action.payload.from, 1)[0]
      workspace.items.splice(action.payload.to, 0, removed)

      return state
    },

    setWorkspaceName(state, action: SetWorkspaceNameAction) {
      const workspace = state.entities[action.payload.workspaceId]

      if (workspace) {
        workspace.name = action.payload.name
      } else {
        throw new Error(`Could not find workspace with id ${action.payload.workspaceId}`)
      }
    },

    setIsEditingWorkspace(state, action: SetIsEditingWorkspaceAction) {
      const workspace = state.entities[action.payload.workspaceId]
      if (workspace) {
        workspace.isEditing = action.payload.isEditing
      } else {
        throw new Error(`Could not find workspace with id ${action.payload.workspaceId}`)
      }
    },

    addGroupToWorkspace: (state, action: AddGroupToWorkspaceAction) => {
      const workspace = state.entities[action.payload.workspaceId] as WorkspaceState

      return workspacesAdapter.updateOne(state, {
        id: action.payload.workspaceId,
        changes: {
          items: workspace.items.concat([
            {
              id: action.payload.entity.id,
              type: 'group',
            },
          ]),
        },
      })
    },

    removeGroupFromWorkspace: (state, action: RemoveGroupFromWorkspaceAction) => {
      const workspace = state.entities[action.payload.workspaceId] as WorkspaceState
      return workspacesAdapter.updateOne(state, {
        id: action.payload.workspaceId,
        changes: {
          items: workspace.items.filter(({ id }) => id !== action.payload.groupId),
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mergeAll, (state, action: MergeAllAction) => {
      return workspacesAdapter.setAll(state, workspaceDocumentsToState(action.payload.workspaces))
    })
  },
})

export const {
  addWorkspace,
  removeWorkspace,
  updateWorkspace,
  setWorkspaceName,
  setAllWorkspaces,
  addGroupToWorkspace,
  removeGroupFromWorkspace,
  moveGroup,
  setIsEditingWorkspace,
} = workspacesSlice.actions

function workspaceDocumentsToState(workspaces: CollectionDocumentV1[]): WorkspaceState[] {
  return workspaces.map((document) => {
    return {
      ...defaultWorkspace,
      id: document.id || defaultWorkspace.id,
      name: document.name || defaultWorkspace.name,
      items: document.items
        ? document.items.map((item) => ({
            id: item.id,
            type: item.type,
          }))
        : defaultWorkspace.items,
    }
  })
}

function localWorkspaceDocumentsToState(workspaces: LocalCollectionDocumentV1[]): WorkspaceState[] {
  return workspaceDocumentsToState(workspaces)
}
