import { AccountDocumentV1 } from '../../state/documents/AccountDocumentV1'
import awsLambdaService from '../aws/aws-lambda-service'
/* eslint-disable no-fallthrough */
import { SynchronizerService } from './synchronizer-service'

const aws = awsLambdaService()
export const LambdaSynchronizerService = (): SynchronizerService => {
  return {
    async initializeRemoteState(document: AccountDocumentV1, callback: (document: AccountDocumentV1) => void) {
      const response = await aws.postAccount(document)

      callback(response)

      return response
    },

    async getRemoteState(callback: (error: any | null, document: AccountDocumentV1 | null) => void) {
      try {
        const remoteDocument = await aws.getAccount()

        callback(null, remoteDocument)
        return { error: null, document: remoteDocument }
      } catch (e: any) {
        callback(e, null)
        return { error: e, document: null }
      }
    },

    async updateRemoteState(document: AccountDocumentV1, callback: (document: AccountDocumentV1) => void) {
      const response = await aws.putAccount(document)

      callback(response)

      return response
    },
  }
}
