import { css } from '@emotion/react'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import Login from '@mui/icons-material/Login'
import MoreVert from '@mui/icons-material/MoreVert'
import { CardHeader, IconButton, Menu, MenuItem } from '@mui/material'
import { nanoid } from 'nanoid'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  addBookmarkToGroup,
  addMenuToGroup,
  GroupState,
  setGroupName,
  setIsEditingGroup,
} from '../../state/reducers/groups-reducer'
import { selectActiveWorkspace } from '../../state/reducers/root-selectors'
import { removeGroupFromWorkspace } from '../../state/reducers/workspaces-reducer'
import { useAppSelector } from '../../state/store'
import { AppMenuLink } from '../buttons/AppMenuLink'
import EditGroupHeader from './EditGroupHeader'
import ViewGroupHeader from './ViewGroupHeader'
import { useAuth } from '../../hooks/use-auth'

export interface GroupHeaderProps {
  group: GroupState
}

export default ({ group }: GroupHeaderProps) => {
  const dispatch = useDispatch()
  const { login } = useAuth()
  const { id: groupId, name, isEditing } = group
  const activeWorkspace = useAppSelector((state) => selectActiveWorkspace(state))
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const workspaceId = useAppSelector((state) => state.activeWorkspace)
  const [menuEl, setMenuEl] = React.useState<EventTarget | null>(null)

  function close(): void {
    return setMenuEl(null)
  }

  return (
    <CardHeader
      css={css`
        background: theme.palette.primary.main;
        color: theme.palette.primary.contrastText;
      `}
      title={
        isEditing ? (
          <EditGroupHeader
            initialValues={{ name }}
            onSubmit={(form) => {
              dispatch(setGroupName({ groupId, name: form.name }))
              dispatch(setIsEditingGroup({ groupId, isEditing: false }))
            }}
            onCancel={() => {
              if (name === '') {
                dispatch(
                  removeGroupFromWorkspace({
                    workspaceId: activeWorkspace.id,
                    groupId,
                  }),
                )
              } else {
                dispatch(setIsEditingGroup({ groupId, isEditing: false }))
              }
            }}
          />
        ) : (
          <ViewGroupHeader group={group} />
        )
      }
      action={
        <div>
          <IconButton
            title={`Options for group: ${group.name}`}
            onClick={(e: any) => setMenuEl(e.currentTarget)}
            css={css`
              color: 'inherit';
            `}
          >
            <MoreVert></MoreVert>
          </IconButton>
          <Menu keepMounted anchorEl={menuEl as any} open={Boolean(menuEl)} onClose={() => close()}>
            {isAuthenticated ? null : (
              <AppMenuLink
                to="/api/login"
                title="Login to edit"
                IconComponent={Login}
                onClick={() => {
                  login()
                  close()
                }}
              />
            )}
            <MenuItem
              disabled={!isAuthenticated}
              onClick={() => {
                close()
                dispatch(
                  addBookmarkToGroup({
                    groupId: group.id,
                    entity: {
                      id: nanoid(),
                      isEditing: true,
                      faviconFailures: 0,
                      name: '',
                      uri: '',
                    },
                  }),
                )
              }}
            >
              <Add
                css={css`
                  margin-right: 1rem;
                `}
                color="primary"
              />
              <span>Add Bookmark</span>
            </MenuItem>
            <MenuItem
              disabled={!isAuthenticated}
              onClick={() => {
                close()
                dispatch(
                  addMenuToGroup({
                    groupId: group.id,
                    entity: {
                      id: nanoid(),
                      name: '',
                      isEditing: true,
                      isDragging: false,
                      items: [],
                    },
                  }),
                )
              }}
            >
              <Add
                css={css`
                  margin-right: 1rem;
                `}
                color="primary"
              />
              <span>Add Menu</span>
            </MenuItem>
            <MenuItem
              disabled={!isAuthenticated}
              onClick={() => {
                close()
                dispatch(
                  setIsEditingGroup({
                    groupId: group.id,
                    isEditing: true,
                  }),
                )
              }}
            >
              <Edit
                css={css`
                  margin-right: 1rem;
                `}
                color="primary"
              />
              <span>Edit Group</span>
            </MenuItem>
            <MenuItem
              disabled={!isAuthenticated}
              onClick={() => {
                close()
                dispatch(
                  removeGroupFromWorkspace({
                    workspaceId,
                    groupId: group.id,
                  }),
                )
              }}
            >
              <Delete
                css={css`
                  margin-right: 1rem;
                `}
                color="secondary"
              />
              <span>Delete Group</span>
            </MenuItem>
          </Menu>
        </div>
      }
    />
  )
}
