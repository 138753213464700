import { css } from '@emotion/react'
import { GroupState } from '../../state/reducers/groups-reducer'
import { selectGroupsByActiveWorkspace } from '../../state/reducers/groups-selectors'
import { useAppSelector } from '../../state/store'
import SortableGroup from './SortableGroup'

export interface SortableGroupsProps {}

export default ({}: SortableGroupsProps) => {
  const groups = useAppSelector((state) => selectGroupsByActiveWorkspace(state))

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      `}
    >
      {groups.map((group: GroupState, i: number) => {
        return <SortableGroup key={group.id} group={group} i={i} />
      })}
    </div>
  )
}
